<template>
  <div style="height: 100vh" class="mt-5">
    <div class="columns is-centered is-vcentered">
      <div class="column is-half" style="height: 100%">
        <div class="card">
          <div
              class="card-header has-background-dark">
            <div
                class="card-header-title has-text-white is-centered">
              <img width="300" :src="`/logos/${site}/bank2.svg`"/>
            </div>
          </div>
          <div
              class="card-content is-centered has-background-white"
              v-if="tab === 0">
            <p
                class="mb-4 p-3 has-text-centered has-background-danger has-text-white">
              {{ errMsg }}
            </p>
            <b-button
                v-if="errCode === 800"
                class="mr-3"
                type="is-warning"
                size="is-small"
                @click="cancel"
            >İptal et ve yeniden dene
            </b-button
            >
            <b-button
                v-if="!errCode"
                class="mr-3"
                type="is-danger"
                size="is-small"
                @click="cancel"
            >İptal et ve geridön
            </b-button
            >
          </div>
          <section class="section is-main-section" v-if="tab === 1">
            <!--
            <card-component title="Yatırım Bilgileriniz">
              <form @submit.prevent="save">
                <b-field label="Ad & Soyad" message="Banka hesabınızdaki ad soyad ile aynı olmalıdır">
                  <b-input v-model="form.name" size="is-small" />
                </b-field>
                <b-field label="Yatırım tutarı" message="Minimum 50 TRY">
                  <b-input v-model="form.amount" size="is-small" disabled />
                </b-field>
                <div class="is-flex is-full is-justify-content-end">
                  <b-button class="mr-3" type="is-danger" size="is-small" @click="cancel">Kapat</b-button>
                  <b-button :disabled="!isValid" type="is-success" size="is-small" native-type="submit">Devam
                    et</b-button>
                </div>
              </form>
            </card-component>
            -->
          </section>
          <section class="section is-main-section" v-if="tab === 2">
            <card-component
                title="Bank Hesaplarımız">
              <form @submit.prevent="complete">
                <b-field
                    label="Banka hesabı seçiniz"
                    message="Lütfen yatırım yapacağınız banka hesabımızı seçip işlemi tamamlayınız.">
                  <b-select
                      v-model="bankAccount"
                      type="is-small">
                    <option
                        v-for="account in accountList"
                        :key="account.id"
                        :value="account.id">
                      {{ account.bankName }} -
                      {{ account.name }} -
                      {{ account.iban }}
                    </option>
                  </b-select>
                </b-field>
                <div
                    class="is-flex is-full is-justify-content-end">
                  <b-button
                      class="mr-3"
                      type="is-danger"
                      size="is-small"
                      @click="cancel"
                  >Kapat
                  </b-button
                  >
                  <b-button
                      :disabled="!bankAccount"
                      type="is-success"
                      size="is-small"
                      native-type="submit"
                  >İşlemi tamamla
                  </b-button
                  >
                </div>
              </form>
            </card-component>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent"
import {IntegrationService, TransactionService, UserService,} from "@/services"

export default {
  name: "BankTransferIframe",
  components: {CardComponent},
  data() {
    return {
      loading: false,
      error: false,
      errMsg: "",
      errCode: null,
      tab: 0,
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        userId: this.$route.query.userId,
        jwt: this.$route.query.jwt,
        processId: this.$route.query.processId,
        failRedirectUrl: this.$route.query.failRedirectUrl,
        successRedirectUrl: this.$route.query.successRedirectUrl,
      },
      bankAccount: null,
      accountList: [],
      transactionId: null
    }
  },
  computed: {
    isValid() {
      return (
          this.form.appKey &&
          this.form.appSecret &&
          this.form.userId &&
          this.form.username &&
          this.form.name &&
          this.form.amount >= 50
      )
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0]
    html.classList = []
    this.init()
  },
  methods: {
    init() {
      UserService.providerCheck(this.form)
          .then(async () => {
            this.tab = 1
            this.save()
          })
          .catch(() => (this.error = true))
    },
    async save() {
      if (this.form.amount < 50) {
        return false
      }
      const request = {
        username: this.form.username,
        name: this.form.name,
        amount: this.form.amount,
        userId: this.form.userId,
        processId: this.form.processId,
        jwt: this.form.jwt,
        failRedirectUrl: this.form.failRedirectUrl,
        successRedirectUrl: this.form.successRedirectUrl,
      }
      const {data} = await TransactionService.hashCreate(
          request,
          this.form.appSecret
      )
      IntegrationService.bankTransferDeposit(
          request,
          data,
          this.form.appKey
      )
          .then((res) => {
            this.accountList = res.data.bankList
            this.transactionId = res.data.transactionId
            this.bankAccount = res.data.bankList.length > 0 ? res.data.bankList[0] : null
            if (this.bankAccount) {
              this.complete()
            } else {
              this.cancel("Uygun hesap bulunamadı")
            }
            // this.tab = 2
          })
          .catch((err) => {
            this.tab = 0
            this.errMsg = err.message
            this.errCode = err.code
            this.transactionId = err.transactionId
          })
    },
    async complete() {
      const request = {
        transactionId: this.transactionId,
        bankAccountId: this.bankAccount.id,
      }
      const {data} = await TransactionService.hashCreate(
          request,
          this.form.appSecret
      )
      IntegrationService.bankTransferNotify(
          request,
          data,
          this.form.appKey
      )
          .then((res) => {
            window.location.href = 'https://payment.' + process.env.VUE_APP_NAME + "/bank-transfer/" + this.transactionId
            // this.$router.push({name: "bankTransferPay", params: {id: this.transactionId}})
          })
          .catch((err) => {
            this.tab = 0
            this.errMsg = err.message
          })
    },
    cancel(msg) {
      if (
          (this.tab !== 0 || this.errCode === 800) &&
          this.transactionId
      ) {
        this.loading = true
        TransactionService.cancel({
          transactionId: this.transactionId,
          message: msg || 'İşlem kullanıcı tarafından iptal edildi.',
        })
            .then((res) => {
              this.$buefy.toast.open({
                message: res.data.message,
                position: "is-top-right",
                type: "is-success",
                duration: 1000,
              })
              if (this.errCode === 800) {
                this.save()
              } else {
                setTimeout(() => {
                  window.location.href = res.data.redirectUrl
                }, 1000)
              }
            })
            .catch((err) =>
                this.$buefy.toast.open({
                  message: err.message,
                  position: "is-bottom",
                  type: "is-info",
                })
            )
            .finally(() => (this.loading = false))
      } else {
        window.location.href = this.$route.query.failRedirectUrl
      }
    },
  },
}
</script>
<style>
.bankAccountLi {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #efefef;
  margin-bottom: 1rem;
}

.bankAccountLi:hover {
  opacity: 0.65;
  cursor: pointer;
  transition: opacity 0.5s;
}

.bankAccountLi:active {
  transform: scale(0.95);
}
</style>
